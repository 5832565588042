import { DealPriceData } from '@poinz/api';
import { useRouter } from 'next/router';
import * as React from 'react';
import { getDealBySlugFetchKeyArgs } from 'api/reactQuery/deal';
import { useQuery } from '@tanstack/react-query';
import { Deal } from '@poinz/api';
import Image from 'next/image';
import { scaledImageLoader, SHOP_IMAGE_PLACEHOLDER } from 'utils/image';
import clsx from 'clsx';

export const usePlainDealPictures = () => {
  const { query } = useRouter();
  const { data: deal } = useQuery<Deal>(
    getDealBySlugFetchKeyArgs({ slug: (query.slug || query.previewId) as string })
  );

  const pictures = React.useMemo(() => {
    if (!deal) {
      return [];
    }
    return [deal.picture, ...(deal.additionalPictures || [])];
  }, [deal]);

  return pictures;
};

export const useGetDealPictures = (): React.ReactElement[] => {
  const { query } = useRouter();
  const { data: deal } = useQuery<Deal>(
    getDealBySlugFetchKeyArgs({ slug: (query.slug || query.previewId) as string })
  );
  const pictures = usePlainDealPictures();

  return pictures.map((picture, i) => (
    <div
      key={`${deal?.name}-image-${i}`}
      className={clsx(
        'max-w-full relative rounded-2xl overflow-hidden md:overflow-auto aspect-[1.25]',
        pictures.length === 1 ? 'aspect-[1]' : 'aspect-[1.25]'
      )}
    >
      <Image
        src={picture?.sourceUrl || SHOP_IMAGE_PLACEHOLDER}
        loader={scaledImageLoader()}
        alt={`${deal?.name}-image-${i}`}
        fill
        className="object-cover"
      />
    </div>
  ));
};

export const getDealDefaultPrice = (data?: Array<DealPriceData>): DealPriceData | undefined => {
  if (!data) {
    return;
  }
  return data.find(priceData => priceData.isDefault);
};
