export const IMAGE_SIZE = Object.freeze({
  X1: 'X1',
  X2: 'X2',
  X3: 'X3',
  X4: 'X4'
});

export const IMAGE_SIZE_PLACEHOLDER = 'x1';

export const scaledImageLoader =
  () =>
  ({ src }: { src: string }) => {
    // always use original image (best quality) and leave oprimization to Next.js
    return src.replace(IMAGE_SIZE_PLACEHOLDER, 'original');
  };

export const SHOP_IMAGE_PLACEHOLDER = '/assets/shop-placeholder.png';

export const DEFAULT_SHOP_IMAGE_SIZE = 64;
