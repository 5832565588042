import * as React from 'react';
import { useFeaturedList } from 'api/reactQuery/featuredItems';
import { FEATURED_SECTION_LIST } from 'utils/contentful/featuredList';
import {
  getSearchBackendItems,
  getSearchContentfulItems
} from 'components/GlobalSearch/GlobalSearch.utils';
import api from 'api/api';
import { ISearchParams } from '@poinz/api';
import { useTranslation } from 'next-i18next';

export const useGlobalSearch = () => {
  const { data: contentfulData } = useFeaturedList(FEATURED_SECTION_LIST.GLOBAL_SEARCH);
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation();
  const initialData = getSearchContentfulItems(t, contentfulData);
  const [data, setData] = React.useState(initialData);
  const getData = async (query: string) => {
    if (!query) {
      setData(initialData);
    } else {
      try {
        setIsLoading(true);
        const res = await api.search.search.getProducts({
          size: 4,
          page: 0,
          query
        } as ISearchParams);
        const formattedData = getSearchBackendItems(t, res.content);

        setData(formattedData);
      } catch (e: any) {
        // can't load global search data
        console.error('e', e.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return {
    isLoading,
    data,
    getData
  };
};
