import { FETCH_KEYS } from './';

interface GetDealFetchKeyByIdArgs {
  apiLoaded: boolean;
  id: string | number;
  isLoggedIn: boolean;
}

interface GetDealFetchKeyBySlugArgs {
  slug: string | number;
}

export const getDealByIdFetchKeyArgs = ({ apiLoaded, id, isLoggedIn }: GetDealFetchKeyByIdArgs) => {
  return [FETCH_KEYS.DEAL_BY_ID, id, apiLoaded, isLoggedIn];
};

export const getDealBySlugFetchKeyArgs = ({ slug }: GetDealFetchKeyBySlugArgs) => {
  return [FETCH_KEYS.DEAL_BY_SLUG_OR_PREVIEW_ID, slug];
};
