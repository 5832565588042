import { DEAL_ASSET_TYPE, IFormattedAffiliateCashback } from '@poinz/api';
import {
  formatAffiliate,
  formatWorldLineCashback,
  getHasMultipleCashbacks,
  getIsLocal,
  getIsOnline,
  getMaxAvailableCashback,
  getMaxCashback
} from 'containers/Shop/AffiliateContainer/AffiliateDetails.utils';
import { ContentfulAsset } from 'model/contentful/asset';
import { routes } from 'utils/routes';

export enum ENTITY_TYPE {
  DEAL = 'DEAL',
  GIFT_CARD_LINE = 'GIFT_CARD_LINE',
  AFFILIATE = 'AFFILIATE'
}

export const isDeal = item => item?.assetType === DEAL_ASSET_TYPE.DEAL;
export const isGiftCardLine = item => !!item.giftCards;
export const isAffiliate = item => item.entityType === ENTITY_TYPE.AFFILIATE;

export interface ShoppingFeaturedSectionItem {
  isOnline: boolean;
  isOffline: boolean;
  logo: string;
  name: string;
  cashback: number | IFormattedAffiliateCashback | null;
  multipleCashbacks?: boolean;
  entityType: ENTITY_TYPE;
  slug: string;
  heroImage?: ContentfulAsset;
  heroLogo?: ContentfulAsset;
  heroTitle?: string;
  heroSubtitle?: string;
  listId?: string;
  businessName: string;
}

const extractContentfulParams = item => ({
  heroImage: item.heroImage,
  heroLogo: item.heroLogo,
  heroTitle: item.heroTitle,
  heroSubtitle: item.heroSubtitle
});

export const formatFeaturedSectionItem = (item): ShoppingFeaturedSectionItem | null => {
  const contentfulParams = extractContentfulParams(item);
  if (isDeal(item)) {
    return {
      logo: item.logo,
      name: item.name,
      cashback: item.cashbackPercent,
      slug: item.slug,
      isOffline: false,
      isOnline: true,
      entityType: ENTITY_TYPE.DEAL,
      businessName: item.businessProfile?.name || '',
      ...contentfulParams
    };
  } else if (isAffiliate(item)) {
    const formattedAffiliate = formatAffiliate(item);
    return {
      logo: item.businessProfile.logo,
      name: item.businessProfile.name,
      cashback: getMaxAvailableCashback({
        maxCashback: getMaxCashback(formattedAffiliate),
        formattedWorldLineCashback: formatWorldLineCashback({
          affiliate: item,
          fallbackCashbackName: ''
        }),
        affiliate: item
      }),
      multipleCashbacks: getHasMultipleCashbacks(formattedAffiliate),
      slug: item.slug,
      isOffline: getIsLocal(item),
      isOnline: getIsOnline(item),
      entityType: ENTITY_TYPE.AFFILIATE,
      businessName: item.businessProfile?.name || '',
      ...contentfulParams
    };
  } else if (isGiftCardLine(item)) {
    return {
      logo: item.businessProfile?.logo || item.picture.sourceUrl,
      name: item.name || item.giftCards?.[0]?.name,
      cashback: item.cashbackPercent,
      slug: item.slug,
      isOffline: false,
      isOnline: true,
      entityType: ENTITY_TYPE.GIFT_CARD_LINE,
      businessName: item.businessProfile?.name || '',
      ...contentfulParams
    };
  }

  return null;
};

export const getItemLink = (entityType?: ENTITY_TYPE, slug?: string) => {
  switch (entityType) {
    case ENTITY_TYPE.DEAL:
      return routes.shop.deal.slug(slug);
    case ENTITY_TYPE.AFFILIATE:
      return routes.shop.affiliate.slug(slug);
    case ENTITY_TYPE.GIFT_CARD_LINE:
      return routes.shop.digitalVoucher.slug(slug);
  }
};
