import { ICashbackType, IEntityTypes, ISearchProduct } from '@poinz/api';
import { getDealDefaultPrice } from 'utils/deal';
import {
  ENTITY_TYPE,
  ShoppingFeaturedSectionItem,
  formatFeaturedSectionItem
} from 'utils/featuredLIst';
import { formatSavingPercentage } from 'utils/number';
import { formatCashbackValue } from 'utils/parse';

export interface GlobalSearchItem {
  label: string;
  slug: string;
  entityType: ENTITY_TYPE;
  businessName: string;
}

const getCashback = (item: ShoppingFeaturedSectionItem | null) => {
  const { cashback } = item || {};
  if (!cashback) return null;
  if (typeof cashback === 'number') {
    return formatCashbackValue(cashback, ICashbackType.PERCENTAGE, 0);
  }
  return formatCashbackValue(
    cashback.campaign?.value || cashback.cashbackValue,
    cashback.campaign?.type || cashback.type,
    0
  );
};

const getEntityTypeFromProductType = (productType: IEntityTypes): ENTITY_TYPE => {
  switch (productType) {
    case IEntityTypes.DEAL:
      return ENTITY_TYPE.DEAL;
    case IEntityTypes.GIFT_CARD:
      return ENTITY_TYPE.GIFT_CARD_LINE;
    default:
      return ENTITY_TYPE.AFFILIATE;
  }
};

export const getSearchContentfulItems = (t: any, items?: Array<any>): Array<GlobalSearchItem> => {
  if (!items?.length) {
    return [];
  }
  const formattedItems = items?.map(item => formatFeaturedSectionItem(item));
  return formattedItems.map(item => {
    let productTypeName = '';

    if (item?.entityType === ENTITY_TYPE.GIFT_CARD_LINE) {
      productTypeName = t('shop.giftCards.giftCard');
    }

    if (item?.entityType === ENTITY_TYPE.DEAL) {
      productTypeName = t('shop.deal.deal');
    }

    return {
      label: `${productTypeName} ${t?.('home.cashback', {
        value: getCashback(item)
      })}`,
      entityType: item?.entityType,
      slug: item?.slug,
      businessName: item?.businessName
    } as GlobalSearchItem;
  });
};

export const getSearchBackendItems = (
  t: any,
  items?: Array<ISearchProduct>
): Array<GlobalSearchItem> => {
  if (!items?.length) {
    return [];
  }

  return items.map(item => {
    // affiliate label
    const businessName = item.businessProfileData?.name || item.name;
    let label = item.name;
    if (item.productType === IEntityTypes.AFFILIATE) {
      if (item.highestCashback) {
        label = `${t('home.cashback', {
          value: formatCashbackValue(item.highestCashback.value, item.highestCashback.type, 0)
        })}`;
      }
    } else if (item.productType === IEntityTypes.DEAL) {
      const options = item.dealData.options;
      const isSoldOut = item.dealData.options.every(option => option.isSoldOut);
      const priceData = getDealDefaultPrice(options) || options[0];

      // if default option has discount
      if (priceData?.discountedPrice) {
        const discontInPercentage = formatSavingPercentage({
          price: priceData.price,
          discountedPrice: priceData.discountedPrice
        });
        label = `${t('shop.deal.deal')} ${t('home.discount', {
          value: discontInPercentage
        })} ${isSoldOut ? `(${t('shop.deal.soldOut').toLowerCase()})` : ''}`;
      }
    } else if (item.productType === IEntityTypes.GIFT_CARD) {
      label = t('shop.giftCards.giftCard');
      if (item.highestCashback) {
        label = `${label} ${t('home.cashback', {
          value: formatCashbackValue(item.highestCashback.value, item.highestCashback.type, 0)
        })}`;
      }
    }
    return {
      label,
      entityType: getEntityTypeFromProductType(item.productType),
      slug: item?.slug,
      businessName
    } as GlobalSearchItem;
  });
};
