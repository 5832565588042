import {
  IAffiliate,
  IAffiliateType,
  ICashbackType,
  IFormattedAffiliateCashback,
  IAffiliateTab,
  IFormattedAffiliate,
  IWorldLineCashback
} from '@poinz/api';
import { TabBarItem } from 'components/TabBar/TabBar';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { getCampaignForCashback } from 'utils/affiliate';

export const useAffiliateTabs = (): TabBarItem<IAffiliateTab>[] => {
  const { t } = useTranslation();
  const { query } = useRouter();

  return [
    {
      label: t('shop.affiliate.tabs.online'),
      href: {
        query: { ...query, type: IAffiliateTab.ONLINE }
      },
      slug: IAffiliateTab.ONLINE,
      scroll: false
    },
    {
      label: t('shop.affiliate.tabs.local'),
      href: {
        query: { ...query, type: IAffiliateTab.IN_STORE }
      },
      slug: IAffiliateTab.IN_STORE,
      scroll: false
    }
  ];
};

export const getIsOnline = affiliate => {
  return (
    (affiliate?.worldlineOnline && affiliate?.worldlineUrl) ||
    affiliate?.apiOnline ||
    affiliate?.affiliateType !== IAffiliateType.NONE
  );
};

export const getIsWorldLineOnline = (affiliate?: IAffiliate) =>
  !!affiliate?.worldlineOnline || !!affiliate?.apiOnline;

export const getIsWorldLine = (affiliate?: IAffiliate) =>
  !!affiliate?.worldlineOffline || !!affiliate?.worldlineOnline || !!affiliate?.apiOnline;
export const getIsLocal = (affiliate?: IAffiliate) => !!affiliate?.worldlineOffline;

export const getHasMultipleCashbacks = (formattedAffiliate: IFormattedAffiliate) => {
  if (formattedAffiliate?.affiliateCashbacks) {
    // apiOnline is considered as wordlineOnline as well
    const wordlineOnline = getIsWorldLineOnline(formattedAffiliate);

    // no cashbacks
    if (!formattedAffiliate.affiliateCashbacks?.length) {
      // must be wordline online and offline in order to be multiple
      return formattedAffiliate.worldlineOffline && wordlineOnline;
    }

    // exactly one cashback
    if (formattedAffiliate.affiliateCashbacks.length === 1) {
      // it's multiple as it already has one affiliateCashback + wordline offline
      return formattedAffiliate.worldlineOffline;
    }

    // more then one cashback, but if it's only wordlineOnline we will ignore affiliate cashbacks so it has to be false
    if (!formattedAffiliate.worldlineOffline && wordlineOnline) {
      return false;
    }

    // more then one cashback
    return true;
  }
};

export const getMaxCashbackForSingleItem = ({
  campaign,
  cashbackValue
}: IFormattedAffiliateCashback): number => campaign?.value || cashbackValue;

export const getMaxCashback = (
  formattedAffiliate: IFormattedAffiliate
): IFormattedAffiliateCashback | null => {
  const cashbacks = formattedAffiliate?.affiliateCashbacks;

  if (!cashbacks) {
    return null;
  }
  // only percentage cashbacks will give prioirty to it
  const percentageCashbacks = cashbacks.filter(cb => cb.type === ICashbackType.PERCENTAGE);
  const amountCashbacks = cashbacks.filter(cb => cb.type === ICashbackType.AMOUNT);

  // if there are no percentage cashbacks take amount cashbacks into consideration
  const relevantCashbacks = percentageCashbacks.length > 0 ? percentageCashbacks : amountCashbacks;
  return relevantCashbacks.reduce((max, cb) => {
    // take into consideration campaign cashback value as well
    const currentMaxValue = getMaxCashbackForSingleItem(max);
    const cashbackValue = getMaxCashbackForSingleItem(cb);

    return cashbackValue > currentMaxValue ? cb : max;
  }, relevantCashbacks[0]);
};

export const getMaxCashbackName = (maxCashback: IFormattedAffiliateCashback | null, t): string => {
  if (maxCashback?.campaign?.eligibility) {
    return t(`shop.affiliate.eligibility.${maxCashback?.campaign?.eligibility}`);
  }

  if (maxCashback?.nameLocalized) {
    return maxCashback?.nameLocalized;
  }

  return t('shop.affiliate.fallbackCashbackName') as string;
};

export const formatAffiliate = (affiliate?: IAffiliate): IFormattedAffiliate => {
  const affiliateCashbacks = affiliate?.affiliateCashbacks?.map(
    aff =>
      ({
        ...aff,
        campaign: getCampaignForCashback(aff.commissionId, affiliate.campaigns)
      }) || []
  );

  return {
    ...affiliate,
    affiliateCashbacks
  } as IFormattedAffiliate;
};

export const formatWorldLineCashback = ({
  affiliate,
  fallbackCashbackName
}: {
  affiliate?: IAffiliate;
  fallbackCashbackName: string;
}): IWorldLineCashback | null => {
  const isWorldLine =
    affiliate?.worldlineOffline || affiliate?.worldlineOnline || affiliate?.apiOnline;

  if (isWorldLine && affiliate && affiliate.worldlineCashback) {
    return {
      cashbackValue: affiliate.worldlineCashback,
      nameLocalized: affiliate.worldlineCashbackName
        ? affiliate.worldlineCashbackName
        : fallbackCashbackName,
      type: ICashbackType.PERCENTAGE,
      campaign: getCampaignForCashback(null, affiliate.campaigns)
    };
  }
  return null;
};

export const getMaxAvailableCashback = ({
  maxCashback,
  formattedWorldLineCashback,
  affiliate
}: {
  maxCashback: IFormattedAffiliateCashback | null;
  formattedWorldLineCashback: IWorldLineCashback | null;
  affiliate?: IAffiliate;
}) => {
  if (!maxCashback && !formattedWorldLineCashback) {
    return null;
  }

  const isLocal = getIsLocal(affiliate);

  const affMaxCashback = maxCashback?.campaign?.value || maxCashback?.cashbackValue || 0;
  const wordlineMaxCashback = formattedWorldLineCashback
    ? getMaxCashbackForSingleItem(formattedWorldLineCashback as IFormattedAffiliateCashback)
    : 0;

  // if affiliate has all 3 cashback values (wordline offline, wordline online and affiliateCashbacks) igonre affiliate cashbacks as wordline has priority
  return wordlineMaxCashback > affMaxCashback ||
    (isLocal && (affiliate?.worldlineOnline || affiliate?.apiOnline))
    ? (formattedWorldLineCashback as IFormattedAffiliateCashback)
    : maxCashback;
};
