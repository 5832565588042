import * as React from 'react';
import { isClientSide } from './general';

export const THEME_SCREEN_SIZE = {
  SM: 640,
  MD: 768,
  LG: 1024,
  XL: 1280,
  '2XL': 1536
};

export enum EScreenSize {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL',
  '2XL' = '2XL'
}

export const isScreenSmallerOrEqualThan = (size: EScreenSize) => {
  if (isClientSide()) {
    return window.innerWidth < THEME_SCREEN_SIZE[size];
  }
  return false;
};

export const isScreenLargerThan = (size: EScreenSize) => {
  if (isClientSide()) {
    return window.innerWidth >= THEME_SCREEN_SIZE[size];
  }
  return false;
};

export const useIsScreenLarger = (screenSize: EScreenSize = EScreenSize.SM) => {
  const [isLarger, setIsLarger] = React.useState(false);

  React.useEffect(() => {
    setIsLarger(isScreenLargerThan(screenSize));
  }, [screenSize]);

  return isLarger;
};
